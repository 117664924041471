.wrapper {
  display: flex;
  flex-direction: column;
  flex-basis: 30%;
  margin-right: 3%;

  &:last-child {
    flex-basis: 31%;
    margin-right: 0;
  }

  img {
    width: 4rem;
    height: 4rem;
  }

  h1 {
    font-family: 'AvenirNextforINTUIT-Bold', arial;
    font-size: 2.125rem;
  }

  p {
    font-size: 1.25rem;
    max-width: 100%;
    text-align: left;
  }
}