.wrapper {
  font-family: 'AvenirNextforINTUIT-Regular', arial;
  font-size: 0.75rem !important;
  color: #0d333f;
  max-height: 30rem;
  overflow: scroll;

  h2 {
    font-size: 0.75rem !important;
    font-family: 'AvenirNextforINTUIT-Regular', arial !important;
  }

  .bold {
    font-weight: bold;
  }

  .red {
    color: red;
  }

  p {
    font-size: 0.75rem !important;
    font-family: 'AvenirNextforINTUIT-Regular', arial !important;
    &.glue {
      margin-top: -1rem;
    }
  }

  ul {
    list-style: circle;
    padding-inline-start: 2.5rem;
    font-size: 0.75rem !important;
    font-family: 'AvenirNextforINTUIT-Regular', arial !important;
  }

  a {
    font-size: 0.75rem !important;
    font-family: 'AvenirNextforINTUIT-Regular', arial !important;
  }

  a:link { color: #0000EE; }
  a:visited { color: #551A8B; }
}