.wrapper {
  display: flex;

  @media (max-width: 768px) {
    &.mobileCoverVisible {
      display: none;
    }
    &.fixedWidth {
      min-width: 90rem;
    }
  }
}