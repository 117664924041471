.wrapper {
  display: inline-block;
  margin-top: 0.5rem;
  padding: 0.3125rem 0.625rem;
  border-radius: 0.25rem;
  background-color: #6b6c72;
  color: #000000;
  font-family: AvenirNextforINTUIT-Demi, arial;
  font-size: 0.75rem;

  &.soon {
    background-color: #000000;
    color: #ffffff;
  }

  &.pending {
    background-color: #ff9331;
  }

  &.in_progress {
    background-color: #07c4d9;
  }

  &.completed {
    background-color: #0fd46c;
  }
}