.button {
  position: static;
  margin-right: 1.5rem;
  //font-weight: normal;

  &.inactive {
    background-color: #dbedee;
    cursor: not-allowed;
  }
}

a, span.link {
  cursor: pointer;
  position: relative;

  img {
    width: 2rem;
    height: 2rem;
  }
}

.bubble {
  position: absolute;
  right: -12.5rem;
  bottom: -5.5rem;
  width: 17.1875rem;
  height: 4.625rem;
  box-sizing: border-box;
  padding: 1rem;
  background: #0d333f;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  border-radius: 0.5rem;
  color: #ffffff;
  display: none;
  align-items: center;
  justify-content: space-around;
  z-index: 2;

  &.visible {
    display: flex;

  }

  span {
    cursor: pointer;
    font-family: 'AvenirNextforINTUIT-Bold', arial;
    font-size: 1.1rem;
  }

  &:after {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 0 1rem 1.3125rem;
    border-color: #0d333f transparent;
    display: block;
    width: 0;
    z-index: 1;
    top: -1.3125rem;
    left: 2.6875rem;
  }

  .icons {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > button {
      margin-left: 1rem;
      button {
        width: 2rem;
        height: 2rem;
        border: none;
        cursor: pointer;

        &.fb {
          background: transparent url(../SocialsShare/facebook.png) no-repeat center;
        }

        &.linkedin {
          background: transparent url(../SocialsShare/linkedin.png) no-repeat center;
        }

        &.twitter {
          background: transparent url(../SocialsShare/twitter.png) no-repeat center;
        }
      }
    }
  }
}