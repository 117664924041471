html,
body,
#container,
#container > div {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  cursor: default;
  font-size: 16px;
}

.container,
#container > div {
  display: flex;
  flex-direction: column;
}

.container {
  flex: 1;
  width: 100%;
  max-width: 970px;
  padding: 0 15px;
  margin: 0 auto;
}

ul, li {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}

h3, h2, h1 {
  margin: 0;
  padding: 0;
}

p {
  margin: 1rem 0;
  padding: 0;
}

@font-face {
  font-family: 'AvenirNextforINTUIT';
  src: url('../public/fonts/AvenirNextforINTUIT-Regular.otf') format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirNextforINTUIT-Bold';
  src: url('../public/fonts/AvenirNextforINTUIT-Bold.otf') format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirNextforINTUIT-Heavy';
  src: url('../public/fonts/AvenirNextforINTUIT-Heavy.otf') format("opentype");
}

@font-face {
  font-family: 'AvenirNextforINTUIT-Demi';
  src: url('../public/fonts/AvenirNextforINTUIT-Demi.otf') format("opentype");
}

@font-face {
  font-family: 'AvenirNextforINTUIT-Medium';
  src: url('../public/fonts/AvenirNextforINTUIT-Medium.otf') format("opentype");
}

@font-face {
  font-family: 'partnermarketing';
  src: url('../public/fonts/partnermarketing.ttf') format("truetype");
}
