.wrapper {
  margin-top: 3.75rem;
  color: #393a3d;

  h1 {
    font-family: AvenirNextforINTUIT-Bold, arial;
    font-size: 3rem;
  }

  p {
    font-family: AvenirNextforINTUIT-Medium, arial;
    font-size: 1rem;
  }

  .boxes {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &.inactive {
      opacity: 0.3;

      > div {
        background-color: #f3f2ef;
      }

      .button {
        background-color: #393a3d;
        color: #ffffff;
      }
    }

    .badge, .prize {
      flex-basis: 49%;
      border-radius: 0.5rem;
      background-color: #ffffff;
      box-shadow: 0 6px 48px 0 rgba(0, 0, 0, 0.2);
      padding: 1.25rem 2.3rem 1.25rem 1.25rem;
      margin-top: 2.5rem;
      box-sizing: border-box;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      min-width: 20rem;

      .texts {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        p {
          margin: 0;
        }

        .button {
          margin-top: 1.25rem;
          position: static;

          &.inactive {
            background-color: #969696;
            color: #ffffff;
            cursor: not-allowed;
          }
        }
      }

      img {
        margin-right: 1.25rem;
        width: 7.5rem;
        height: 7.5rem;
      }
    }
  }

  .tcModal {
    z-index: 101 !important;
  }

  .modalText {
    width: 55rem !important;

    h1 {
      margin-bottom: 0.75rem;
    }
  }

  .modalWrapper {
    width: 31.5rem !important;
    span.link {
      font-family: AvenirNextforINTUIT-Demi, arial;
      text-decoration: underline;
      font-weight: bold;
      cursor: pointer;
    }

    .terms {
      font-family: AvenirNextforINTUIT-Regular, arial;
    }
  }

  .modal {
    div {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-family: AvenirNextforINTUIT-Regular, arial;
      font-size: 1rem;
      line-height: 1.5rem;
      margin-bottom: 1.25rem;

      img {
        width: 4rem;
        height: 4rem;
        float: left;
        margin-right: 1.25rem;
      }

      p {
        margin: 0;
      }
    }
  }
}