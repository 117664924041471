.wrapper {
  background-color: #fff;
  box-shadow: 0 6px 48px 0 rgb(0 0 0 / 20%);
  border-radius: 0.25rem;
  flex-basis: 32%;
  padding: 1.25rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 15rem;
  margin-top: 1.25rem;

  .top {
    display: flex;

    img {
      width: 4rem;
      height: 4rem;
    }

    .title {
      display: flex;
      flex-direction: column;
      margin-left: 1rem;

      span {
        font-family: AvenirNextforINTUIT-Medium, arial;
        font-size: 1rem;
        margin-bottom: 0.5rem;
      }

      h1 {
        font-family: AvenirNextforINTUIT-Bold, arial;
        font-size: 1.25rem;
      }
    }
  }

  p {
    font-size: 1.5rem;

    &.terms {

      font-weight: normal;
      font-size: 1rem;
      margin-top: 1.25rem;

      input[type="checkbox"] {
        accent-color: #2ca01c;
      }

      span.link {
        font-family: 'AvenirNextforINTUIT-Demi', arial;
        text-decoration: underline;
        font-weight: bold;
        cursor: pointer;
      }
    }
  }

  .description {
    font-family: AvenirNextforINTUIT-Medium, arial;
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 1.25rem;
  }

  .bottom {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    .button {
      position: static;
      margin-right: 1.5rem;
      //font-weight: normal;

      &.inactive {
        background-color: #dbedee;
      }

      &.pending {
        background-color: #ff9331;
        //padding: 0.3125rem 0.625rem;
        //font-size: 0.75rem;
      }
    }

    a, span.link {
      cursor: pointer;
      img {
        width: 2rem;
        height: 2rem;
      }
    }

    .socials {
      position: absolute;
      right: -3rem;
      bottom: -4rem;
      background-color: #ffffff;
      padding: 0.5rem;
      display: none;
      justify-content: center;
      align-items: center;
      box-shadow: 0 6px 48px 0 rgb(0 0 0 / 20%);

      &.visible {
        display: flex;
      }

      button {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .tip {
      flex-basis: 35%;
      font-size: 0.875rem;
    }
  }

  .surveyModal {
    .videoModalText,
    .surveyModalText {
      background-color: #fff;
      width: 45rem;
      max-height: 50rem;

      h1 {
        font-size: 2.5rem;
        margin: -1.3rem 0 2rem;
      }
    }

    .surveyModalText {
      overflow: scroll;
    }

    .videoModalText {
      width: 48rem;
      h1 {
        margin: -1.3rem 2rem 2rem 0;
      }
    }
  }
}