.wrapper {
  padding: 4.375rem 2.5rem;
  font-family: 'AvenirNextforINTUIT', arial;
  background-color: #ffffff;

  @media (max-width: 768px) {
    &.mobileCoverVisible {
      display: none;
    }
    &.fixedWidth {
      min-width: 90rem;
    }
  }

  .title {
    font-family: 'AvenirNextforINTUIT-Bold', arial;
    color: blue;
    font-size: 2rem;
  }

  .text {
    color: #828385;
    font-size: 0.9rem;
    margin: 0;
    padding: 0;

    &.rights {
      margin: 1.875rem 0;
    }
  }
}