@import url(https://fonts.googleapis.com/css?family=Lato:700);

// -- vars
$bg-color: #34495e;
$default-size: 4rem;
$label-font-size: $default-size / 4;
$label-font-size-redo: $default-size * 4;

// -- mixins
@mixin size($width, $height) {
  height: $height;
  width: $width;
}

@mixin draw-progress($progress, $color) {
  .pie {
    .halfCircle {
      border-color: $color;
    }

    .leftSide {
      transform: rotate($progress * 3.6deg);
    }

    @if $progress <= 50 {
      .rightSide {
        display: none;
      }
    } @else {
      clip: rect(auto, auto, auto, auto);

      .rightSide {
        transform: rotate(180deg);
      }
    }
  }
}

@mixin draw-progress--solid($progress, $color, $bg-color) {
  background: linear-gradient(to right, $color 50%, $bg-color 50%);

  &:before {
    @if $progress <= 50 {
      background: $bg-color;
      transform: rotate((100 - (50 - $progress)) / 100 * 360deg * -1);
    } @else {
      background: $color;
      transform: rotate((100 - $progress) / 100 * 360deg);
    }
  }
}

.chartsContainer {
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  &:after {
    clear: both;
    content: '';
    display: table;
  }
}

.pieWrapper {
  @include size($default-size, $default-size);
  float: left;
  position: relative;

  &:nth-child(3n + 1) {
    clear: both;
  }

  .pie {
    @include size(100%, 100%);
    clip: rect(0, $default-size, $default-size, $default-size / 2);
    left: 0;
    position: absolute;
    top: 0;

    .halfCircle {
      @include size(100%, 100%);
      border: ($default-size / 8) solid #0fd46c;
      border-radius: 50%;
      clip: rect(0, $default-size / 2, $default-size, 0);
      left: 0;
      position: absolute;
      top: 0;
    }
  }

  .shadow {
    @include size(100%, 100%);
    border: $default-size / 8 solid #6b778c;
    border-radius: 50%;

    &.shadowDark {
      border-color: #0d333f;
    }
  }

  &.progress11 {
    @include draw-progress(11, #0fd46c);
  }

  &.progress22 {
    @include draw-progress(22, #0fd46c);
  }

  &.progress33 {
    @include draw-progress(33, #0fd46c);
  }

  &.progress44 {
    @include draw-progress(44, #0fd46c);
  }

  &.progress55 {
    @include draw-progress(55, #0fd46c);
  }

  &.progress66 {
    @include draw-progress(66, #0fd46c);
  }

  &.progress77 {
    @include draw-progress(77, #0fd46c);
  }

  &.progress88 {
    @include draw-progress(88, #0fd46c);
  }

  &.progress100 {
    @include draw-progress(100, #0fd46c);
  }
}
