.wrapper {
  margin: 1rem 0 2.8rem;
  font: 1rem AvenirNextforINTUIT, arial;
  color: #0d333f;

  &.separate {
    margin-top: 2.8rem;
  }

  &.invalid {
    color: #ff0000;

    textarea {
        border-color: #ff0000;
    }
  }

  label {
    font: 1rem AvenirNextforINTUIT, arial;
    line-height: 1.5;
    &.bold {
      font: 1rem AvenirNextforINTUIT-Bold, arial;
      line-height: 1.5;
    }
  }

  textarea {
    margin-top: 0.75rem;
    width: 99%;
    height: 3.75rem;
    resize: none;
  }

  .charCounter {
    display: flex;
    justify-content: flex-end;
  }
}