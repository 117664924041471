.wrapper {
  border-radius: 2.5rem;
  overflow: hidden;
  width: 100%;

  .bar {
    display: block;
    background: rgba(0, 0, 0, 0.075);

    .progress {
      display: block;

      background: #0fd46c;
      color: #fff;
      padding: 0.25rem;
    }
  }
}