.main {
  flex-basis: 73.7%;
  position: relative;
  padding: 1.25rem 2.5rem 1.25rem 1.25rem;

  .link {
    cursor: pointer;
    font-family: AvenirNextforINTUIT-Demi, arial;
    display: inline-block;
    position: absolute;
    top: 2.5rem;
    left: 2.5rem;
  }

  .wrapper {
    padding-top: 5.5rem;
  }
}