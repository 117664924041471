.wrapper {
  padding: 0 1.25rem;

  .title {
    font-family: AvenirNextforINTUIT-Heavy, arial;
    font-size: 4.5rem;
    color: #0d333f;
  }
  .texts {
    width: 67%;

    .subtitle {
      font-family: AvenirNextforINTUIT-Bold, arial;
      font-size: 1.25rem;
      line-height: 1.75rem;
      color: #393a3d;
      margin: 1.25rem 0 1rem;
    }

    ol, .description {
      font-family: AvenirNextforINTUIT-Regular, arial;
      font-size: 1rem;
      line-height: 1.5rem;
      color: #393a3d;
    }

    ol {
      padding-inline-start: 1.25rem;
    }
  }
}