.button {
  padding: 1rem 1.25rem;
  border-radius: 0.25rem;
  background-color: #0fd46c;
  color: #000000;
  border: none;
  cursor: pointer;

  position: absolute;
  top: 2.5rem;
  right: 2.5rem;

  font-family: 'AvenirNextforINTUITDemi', arial;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
}