.wrapper {
  background-color: #fff;
  box-shadow: 0 6px 48px 0 rgb(0 0 0 / 20%);
  border-radius: 0.25rem;
  flex-basis: 31.5%;
  padding: 1.25rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 1.25rem;

  &.loose {
    background-color: #efefef;
  }

  &.inactiveBadgeBox {
    opacity: 0.4;

    button {
      background-color: #6b6c72;
    }
  }

  .top {
    display: flex;
    img {
      width: 4rem;
      height: 4rem;
    }

    .title {
      display: flex;
      flex-direction: column;
      margin-left: 1.25rem;

      span {
        font-family: AvenirNextforINTUIT-Medium, arial;
        font-size: 1rem;
        margin-bottom: 0.5rem;
      }

      h1 {
        font-family: AvenirNextforINTUIT-Bold, arial;
        font-size: 1.25rem;
      }
    }
  }

  .hint {
    font-family: 'AvenirNextforINTUIT-Regular', arial;
    font-size: 0.675rem;
    line-height: 0.875rem;
  }

  p {
    font-size: 1.5rem;

    &.terms {

      font-weight: normal;
      font-size: 1rem;
      margin-top: 1.25rem;

      input[type="checkbox"] {
        accent-color: #2ca01c;
      }

      span.link {
        font-family: 'AvenirNextforINTUIT-Demi', arial;
        text-decoration: underline;
        font-weight: bold;
        cursor: pointer;
      }
    }
  }

  .description {
    font-family: AvenirNextforINTUIT-Medium, arial;
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 1.25rem;
  }

  .bottom {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;

    .button {
      position: static;
      margin-right: 1.5rem;
      margin-top: 1rem;
      //font-weight: normal;

      &.inactive {
        background-color: #dbedee;
        cursor: not-allowed;
      }
    }

    a, span.link {
      cursor: pointer;
    }
  }

  .modalText {
    width: 55rem !important;

    h1 {
      margin-bottom: 0.75rem;
    }
  }
}