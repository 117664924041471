.wrapper {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(13, 44, 54, 0.91);
  justify-content: center;
  align-items: center;

  &.open {
    display: flex;
  }

  &.close {
    display: none;
  }

  .content {
    position: relative;
    box-sizing: border-box;
    background-color: #f4f5f8;
    border-radius: 0.5rem;
    background-clip: padding-box;
    outline: 0;
    color: #0d333f;
    padding: 2.5rem;
    width: 27.5rem;

    .title {
      font-family: 'AvenirNextforINTUIT-Bold', arial;
      font-size: 1.25rem;
      margin-top: 0.625rem;
    }

    .text {
      font-family: 'AvenirNextforINTUIT-Medium', arial;
      font-size: 1rem;
      line-height: 1.5rem;
      margin: 1.25rem 0;
    }

    input[type="checkbox"] {
      accent-color: #2ca01c;
    }

    a {
      font-weight: bold;
      color: #000000;
    }

    .actions {
      display: flex;
      justify-content: space-between;

      .button {
        cursor: pointer;
        height: 3rem;
        padding: 1rem 1.25rem;
        border-radius: 0.25rem;
        background-color: #0fd46c;
        margin-top: 0.25rem;
        color: #000000;
        border: none;

        font-family: 'AvenirNextforINTUITDemi', arial;
        font-size: 1rem;
        font-weight: bold;
        text-align: center;

        &.black {
          background-color: #0d333f;
          color: #ffffff;
        }

        &.disabled {
          background-color: #969696;
          color: #ffffff;
        }
      }
    }
  }

  .close {
    position: absolute;
    right: 2.5rem;
    top: 2.3rem;
    width: 1.75rem;
    height: 1.75rem;
  }
  .close:before, .close:after {
    position: absolute;
    left: 0.875rem;
    top: -0.25rem;
    content: ' ';
    height: 2.5rem;
    width: 0.125rem;
    background-color: #0d2c36;
  }
  .close:before {
    transform: rotate(45deg);
  }
  .close:after {
    transform: rotate(-45deg);
  }
}