.wrapper {
  background-color: #fff;
  box-shadow: 0 6px 48px 0 rgb(0 0 0 / 20%);
  border-radius: 0.25rem;
  flex-basis: 31.5%;
  padding: 1.25rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 1rem;
  min-width: 14rem;

  .texts {
    .top {
      display: flex;
      flex-wrap: wrap;

      > div {
        flex-basis: 50%;
      }

      img {
        width: 8rem;
        height: 8rem;
        margin-bottom: 1rem;
      }

      .title {
        display: flex;
        flex-direction: column;
        //margin-left: 1rem;

        span {
          font-family: AvenirNextforINTUIT-Medium, arial;
          font-size: 1rem;
          margin-bottom: 0.5rem;
        }

        h1 {
          font-family: AvenirNextforINTUIT-Bold, arial;
          font-size: 1.5rem;
        }
      }
    }
  }

  p {
    font-size: 1.5rem;

    &.terms {

      font-weight: normal;
      font-size: 1rem;
      margin-top: 1.25rem;

      input[type="checkbox"] {
        accent-color: #2ca01c;
      }

      span.link {
        font-family: 'AvenirNextforINTUIT-Demi', arial;
        text-decoration: underline;
        font-weight: bold;
        cursor: pointer;
      }
    }
  }

  .description {
    font-family: AvenirNextforINTUIT-Medium, arial;
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 1.25rem;
    margin-top: 0;
  }

  .bottom {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    .button {
      position: static;
      margin-right: 1.5rem;
      //font-weight: normal;

      &.inactive {
        background-color: #dbedee;
        cursor: not-allowed;
      }
    }

    a, span.link {
      cursor: pointer;
    }

    .socials {
      position: absolute;
      right: -3rem;
      bottom: -4rem;
      background-color: #ffffff;
      padding: 0.5rem;
      display: none;
      justify-content: center;
      align-items: center;
      box-shadow: 0 6px 48px 0 rgb(0 0 0 / 20%);

      &.visible {
        display: flex;
      }

      button {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}