.wrapper {
  display: none;

  @media (max-width: 768px) {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #f4f5f8;
    justify-content: space-between;
    align-items: center;
    display: none;
    flex-direction: column;

    padding: 3rem 2.5rem;
    box-sizing: border-box;

    &.open {
      display: flex;
    }

    .part {
      width: 100%;

      &.logo {
        img {
          position: static;
        }
      }

      &.top {
        h1 {
          font-family: 'AvenirNextforINTUIT-Bold', arial;
          font-size: 2rem;
        }

        p {
          font-family: 'AvenirNextforINTUIT-Regular', arial;
          font-size: 1rem;
        }

        .link {
          font-weight: bold;
          text-decoration: underline;
          cursor: pointer;
        }
      }

      &.bottom {
        > div {
          margin: 0 -2.5rem;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;

          p {
            font-size: 1rem !important;
          }
        }
      }
    }
  }
}