@value size: 50rem;
@value sizeSmall: 29px;
@value sizeInput: 15px;

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.loadingContainer.loading {
  position: relative;
  min-height: 50rem;
  flex: 1 1 auto;
}

.loadingContainer.loading > * {
  opacity: 0 !important;
}

.loadingContainer.loading > .spinnerWrapper {
  opacity: 1 !important;
}

.spin:before {
  -webkit-animation: spin 1s infinite linear;
  animation: spin 1s infinite linear;
  text-rendering: auto;
  display: inline-block;
}

.spinnerWrapper {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.spinner {
  position: absolute;
  width: 5rem;
  height: 5rem;
  top: 50%;
  left: 0;
  right: 0;
  margin: 0 auto !important;
  transform: translateY(-50%);
}

.spin {
  display: block;
  height: 5rem;
  font-size: 5rem;
}

.global .spinnerWrapper {
  position: fixed;
  background: rgba(77, 77, 77, 0.7);
  z-index: 1000;
}

.global .spinner {
  position: fixed;
  background-color: #fff;
  border-radius: 0.25rem;
  z-index: 1001;
}

.opaque > * {
  opacity: 0 !important;
}

.opaque > .spinnerWrapper {
  opacity: 1 !important;
}
