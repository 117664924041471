.wrapper {
  padding: 1.25rem;
  margin-bottom: 1.25rem;
  border-radius: 0.25rem;
  box-shadow: 0 3.8px 30px 0 rgba(0, 0, 0, 0.2);

  background-color: #ffffff;
  color: #0d333f;

  display: flex;

  &.darkMode {
    background-color: #0d333f;
    color: #ffffff;
  }

  .value {
    margin-left: 1.5625rem;
    display: flex;
    flex-direction: column;

    .label {
      font-family: AvenirNextforINTUIT-Medium, arial;
      font-size: 0.75rem;
    }

    .percent {
      font-family: AvenirNextforINTUIT-Bold, aril;
      font-size: 2.125rem;

      > span {
        font-size: 0.75rem;
        margin-left: 0.5rem;
        font-family: 'AvenirNextforINTUIT-Regular', arial;
      }
    }
  }
}