.welcomeBanner {
  @media (max-width: 768px) {
    &.mobileCoverVisible {
      display: none;
    }
    &.fixedWidth {
      min-width: 90rem;
    }
  }


  padding: 6.5rem 5.25rem 6.5rem 5.25rem;
  box-sizing: border-box;
  color: #ffffff;
  background: transparent url(./intro_bg.png) no-repeat center;
  background-size: cover;
  position: relative;
  overflow-wrap: break-word;

  h1 {
    font-family: 'AvenirNextforINTUIT-Heavy', arial;
    font-size: 6.75rem;
    line-height: 7.25rem;
    max-width: 54rem;
  }

  p {
    font-size: 1.5rem;
    max-width: 54rem;

    &.terms {
      font-family: 'AvenirNextforINTUIT-Regular', arial;
      font-size: 1rem;
      margin-top: 1.25rem;

      input[type="checkbox"] {
        accent-color: #2ca01c;
      }

      span.link {
        font-family: 'AvenirNextforINTUIT-Demi', arial;
        text-decoration: underline;
        font-weight: bold;
        color: #ffffff;
        cursor: pointer;
      }
    }
  }

  .button {
    padding: 1rem 1.25rem;
    border-radius: 0.25rem;
    background-color: #0fd46c;
    margin-top: 1.625rem;
    color: #000000;
    border: none;

    font-family: 'AvenirNextforINTUITDemi', arial;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
  }
}

.benefits {
  @media (max-width: 768px) {
    &.mobileCoverVisible {
      display: none;
    }
    &.fixedWidth {
      min-width: 90rem;
    }
  }


  background-color: #f3f2ef;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3.75rem 0 5rem;

  h1{
    color: #0d333f;
    font-family: 'AvenirNextforINTUIT-Bold', arial;
    font-size: 3rem;
    line-height: 3.75rem;
  }

  p {
    font-size: 1.25rem;
    color: #000000;
    max-width: 74%;
    text-align: center;
  }

  .benefitList {
    width: 74.5%;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: space-between;
    margin-top: 1.5rem;
  }
}

.tcModal {
  z-index: 101 !important;
}

.modalText {
  width: 55rem !important;

  h1 {
    margin-bottom: 0.75rem;
  }
}

.modalTerms {
  font-family: AvenirNextforINTUIT-Regular, arial;
  span.link {
    font-family: AvenirNextforINTUIT-Demi, arial;
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
  }
}

@media (max-width: 768px) {
  .mobileCoverVisible {
    display: none;
  }
}