.wrapper {
  .icon {
    width: 2.875rem;
    height: 2.875rem;
    background-color: #6b778c;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-size: 1rem;

    &.notActive {
      background-color: #0d333f;
    }
  }

  img {
    width: 2.875rem;
    height: 2.875rem;
  }
}