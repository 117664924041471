.wrapper {
  padding: 1.5625rem;
  margin-bottom: 1rem;
  border-radius: 0.25rem;
  box-shadow: 0 3.8px 30px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  color: #000000;
  position: relative;
  cursor: pointer;

  &.inactive {
    cursor: default;
    background-color: #dbedee;

    .data {
      opacity: 0.6;
      p {
        margin: 0.75rem 0 0.5rem;
      }
      span {
        color: #ffffff;
      }
    }
    img {
      opacity: 0.05;
    }
  }

  &.dark {
    background-color: #0d333f;
    color: #ffffff;
  }

  p {
    font-size: 1rem;
  }

  h1 {
    font-family: 'AvenirNextforINTUIT-Bold', arial;
    font-size: 1.75rem;
  }

  .stars {
    font-size: 1.5625rem;
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;

    svg {
      margin-left: 0.625rem;
    }
  }
}