.icon {
  font-family: 'partnermarketing';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
}

.icon.dark {
  color: #333;
}

.icon-loading:before {
  content: '\e615';
}