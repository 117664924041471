.wrapper {
  padding: 1.5625rem;
  margin-bottom: 1rem;
  border-radius: 0.25rem;
  box-shadow: 0 3.8px 30px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  color: #000000;
  position: relative;
  cursor: pointer;

  .iconWrapper {
    .icon {
      width: 2.875rem;
      height: 2.875rem;
      //background-color: #0d333f;
      //border-radius: 50%;
      //display: flex;
      //justify-content: center;
      //align-items: center;
      //color: #ffffff;
      //font-size: 1rem;
    }

    img {
      width: 2.875rem;
      height: 2.875rem;
    }
  }

  &.inactive {
    cursor: default;
    background-color: #dbedee;

    .data {
      opacity: 0.6;
    }
    .iconWrapper {
      opacity: 0.05;
    }
  }

  p {
    font-size: 1rem;
  }

  h1 {
    margin-top: 1rem;
    font-family: 'AvenirNextforINTUIT-Bold', arial;
    font-size: 1.75rem;
  }
}