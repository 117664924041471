.wrapper {
  .top, .bottomText {
    width: 61.5%;
    padding: 0 1.25rem;

    .title {
      font-family: AvenirNextforINTUIT-Heavy, arial;
      font-size: 4.5rem;
      color: #0d333f;
    }

    .subtitle {
      font-family: 'AvenirNextforINTUIT-Bold', arial;
      font-size: 1.25rem;
      line-height: 1.75rem;
      color: #393a3d;

      a {
        color: inherit;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .description {
      font-family: AvenirNextforINTUIT-Regular, arial;
      font-size: 1rem;
      line-height: 1.5rem;
      color: #393a3d;
      margin: 1rem 0;
    }
  }

  .bottomText {
    width: 70%;

    a {
      color: #055393;
      text-decoration: none;
    }
  }

  .actions {
    margin-top: 3.75rem;

    .task {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 2.5rem 0 1.25rem;

      p {
        font-family: 'AvenirNextforINTUIT-Medium', arial;
        font-size: 1rem;
      }

      span {
        font-family: 'AvenirNextforINTUIT-Bold', arial;
        font-size: 1.75rem;
      }
    }

    .activities {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      flex-wrap: wrap;

      div {
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .tcModal {
    z-index: 102 !important;
  }

  .modalText {
    width: 55rem !important;

    h1 {
      margin-bottom: 0.75rem;
    }
  }

  .modalWrapper {
    width: 31.5rem !important;
    span.link {
      text-decoration: underline;
      font-weight: bold;
      cursor: pointer;
    }
  }

  .modal {
    div {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-family: AvenirNextforINTUIT-Medium, arial;
      font-size: 1rem;
      line-height: 1.5rem;
      margin-bottom: 1.25rem;

      img {
        width: 4rem;
        height: 4rem;
        float: left;
        margin-right: 1.25rem;
      }

      p {
        margin: 0;
      }
    }
  }
}