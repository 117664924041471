.wrapper {
  .top {
    width: 67%;
    padding: 0 1.25rem;

    .title {
      font-family: AvenirNextforINTUIT-Heavy, arial;
      font-size: 4.5rem;
      color: #0d333f;
    }

    .subtitle {
      font-family: 'AvenirNextforINTUIT-Bold', arial;
      font-size: 1.25rem;
      color: #393a3d;
    }
  }

  .description {
    font-family: AvenirNextforINTUIT-Regular, arial;
    font-size: 1rem;
    color: #393a3d;
    margin: 1rem 0 0;
  }

  .actions {
    margin-top: 3.75rem;
    padding: 0 1.25rem;

    .description {
      width: 67%;
    }

    h2 {
      font-family: 'AvenirNextforINTUIT-Bold', arial;
      font-size: 3rem;
      color: #0d333f;
    }

    .task {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 2.5rem 0 1.25rem;

      p {
        font-family: 'AvenirNextforINTUIT-Medium', arial;
        font-size: 1rem;
      }

      span {
        font-family: 'AvenirNextforINTUIT-Bold', arial;
        font-size: 1.75rem;
      }
    }

    .activities {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: stretch;
      margin: 0 -1.25rem;

      div {
        margin-right: 2%;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .modal {
    div {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-family: AvenirNextforINTUIT-Medium, arial;
      font-size: 1rem;
      line-height: 1.5rem;
      margin-bottom: 1.25rem;

      img {
        width: 4rem;
        height: 4rem;
        float: left;
        margin-right: 1.25rem;
      }

      p {
        margin: 0;
      }
    }
  }

  .inactiveBadgeBox {
    opacity: 0.4;

    button {
      background-color: #6b6c72;
      color: #ffffff;
    }
  }
}