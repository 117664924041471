.wrapper {
  background-color: #ffffff;
  box-shadow: 0 6px 48px 0 rgba(0, 0, 0, 0.2);
  margin-top: 3.75rem;
  padding: 2.5rem;
  box-sizing: border-box;
  color: #0d333f;
  position: relative;
  width: 67.4%;
  min-width: 20rem;

  .top {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .topLeft {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;

      .icon {
        background-color: #0d333f;
        width: 4rem;
        height: 4rem;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        float: left;
        margin-right: 2.5rem;

        img {
          width: 4rem;
          height: 4rem;
        }

        //
        //.sign {
        //  color: #ffffff;
        //  font-size: 2.5rem;
        //}
      }

      .title {
        padding-top: 0.25rem;
        float: left;

        p {
          margin: 0;
        }

        h2 {
          font-family: 'AvenirNextforINTUIT-Bold', arial;
          font-size: 3rem;
        }
      }

      .description {
        font-family: 'AvenirNextforINTUIT-Medium', arial;
        font-size: 1rem;
        line-height: 1.5rem;
        clear: both;
        padding: 1rem 0;
      }
    }

    .topRight {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      button {
        //margin-left: 7.75rem;
        position: static;
      }
    }
  }
  hr {
    height: 8px;
    background-color: #e7ebec;
    border-radius: 4px;
    border: none;
    margin: 1.25rem 0;
  }

  .stars {
    font-size: 2rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    svg {
      margin-right: 4%;
    }

    img {
      width: 3.375rem;
      height: 3.375rem;
    }
  }
}