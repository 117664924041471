.form {
  padding-right: 3rem;

  label {
    font: 1rem AvenirNextforINTUIT-Bold, arial;
    display: block;

    &.general {
      font-family: AvenirNextforINTUIT, arial;
      margin-top: -2.8rem;
    }

    &.invalid {
      color: #ff0000;
    }
  }

  button {
    cursor: pointer;
    height: 3rem;
    padding: 1rem 1.25rem;
    border-radius: 0.25rem;
    background-color: #0fd46c;
    margin-top: 0.25rem;
    color: #000000;
    border: none;

    font-family: 'AvenirNextforINTUITDemi', arial;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
  }
}