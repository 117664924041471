.wrapper {
  color: #0d333f;
  margin-top: 1rem;

  &.invalid {
    color: #ff0000;

    .scale {
      .optionWrapper {
        .circle {
          border-color: #ff0000;
          color: #ff0000;
        }
      }
    }
  }

  label {
    font-family: AvenirNextforINTUIT-Bold, arial;
    font-size: 1rem;
    line-height: 1.5;
  }

  .scale {
    display: flex;
    margin-top: 1rem;
    justify-content: space-between;

    &.choice {
      justify-content: flex-start;
      align-items: center;

      .circle {
        &.active {
          background: #fff;
          border: 1px solid #0d333f;

          .innerCircle {
            display: flex;
            justify-content: center;
            align-items: center;

            border-radius: 50%;
            width: 1.75rem;
            height: 1.75rem;

            background: #0d333f;
            border: 1px solid #fff;
          }
        }
      }
    }

    .optionWrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .choiceLabel {
        margin: 0 1.25rem 0 0.75rem;
      }

      .circle {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        border-radius: 50%;
        width: 2.625rem;
        height: 2.625rem;

        background: #fff;
        border: 1px solid #0d333f;
        color: #0d333f;

        font: 1.125rem AvenirNextforINTUIT, arial;

        &.active {
          background: #0d333f;
          border: 1px solid #fff;
          color: #fff;
        }
      }
    }
  }
}
